import React from "react"
import { Themed } from "theme-ui"
import Layout from "../components/layout"

const NotFoundPage = () => (
  <Layout title="404: Not found">
    <Themed.h1>Page not found</Themed.h1>
  </Layout>
)

export default NotFoundPage
